.wallet-wrapper {
  padding-left: 0.7rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.wallet-key {
  display: flex;
  align-items: center;
  border: none;
  border-radius: 10px;
  height: 48px;
  padding: 0px 8px;

  &:focus {
    color: unset;
  }
}

h5 {
  font-weight: normal;
}

