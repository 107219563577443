@metaplex-primary-color: #00ffbd;
@surge-20: #00cc82;
@tungsten-100: #06101a;
@tungsten-60: #547595;
@tungsten-50: #0d1b28;
@tungsten-40: #7ba4c7;
@black-100: #121212;
@solana-green: #3d4844;
@grey-20: rgba(255, 255, 255, 0.7);
@grey-30: rgba(255, 255, 255, 0.5);
@grey-50: rgba(255, 255, 255, 0.1);
@grey-60: rgba(255, 255, 255, 0.05);
@grey-70: #2a2a2a;
@grey-100: #1e1e1e;
@mobile-breakpoint: 800px;
@mobile-xs-max: 520px;
@mobile-xs-min: 521px;

@fg-color: #ddd;
@bg-color: #000;
@primary-color: #14F195;
@secondary-color: #9945FF;
@error-color: #F13C20;
// @bg-color: #0f172a;
@subtext-color: #c7c6c5;
@accent-color: #ffffff;

@fontFamilyInter: Inter, sans-serif;

@error-purple: #D83AEB;

:root {
  --bg-color: @bg-color;
  --primary-color: @primary-color;
}
