
@import '~@solana/wallet-adapter-react-ui/styles.css';

.wallet-adapter-modal-wrapper {
  // background: @bg-color;
}

@keyframes wallet-adapater-fade {
  0% {
    background-color: inherit;
  }
  100% {
    background-color: #1A1F2E;
  }
}

.wallet-adapter-modal-button-close {
  height: inherit;
  background-color: #1A1F2E;
}

.wallet-adapter-modal-button-close:hover {
  background-color: #2A2F3E;
  animation: wallet-adapater-fade 100ms;
}

@keyframes wallet-adapater-fade {
  0% {
    fill: inherit;
  }
  100% {
    fill: #FFF;
  }
}

.wallet-adapter-modal-button-close:hover svg {
  fill: #fff;
  animation: close-svg-fade 100ms;
}

.wallet-adapter-button:not([disabled]):hover {
  background-color: #1A1F2E;
  animation: wallet-adapater-fade 100ms;
}

.wallet-adapter-dropdown-list-item:not([disabled]):hover {
  background-color: #1A1F2E;
  animation: wallet-adapater-fade 100ms;
}

.wallet-adapter-modal-list-more {
  height: inherit;
  display: grid;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  span {
    // depends on parent being grid
    justify-self: flex-end;
  }

  &:hover {
    background-color: #1A1F2E;
    animation: wallet-adapater-fade 100ms;
  }
}

.wallet-adapter-modal-middle-button {
  color: black;
  background-color: @primary-color;

  &:hover, &:focus {
    border-color: rgba(@primary-color, 0.75);
    background-color: rgba(@primary-color, 0.75);
    animation: connector-fade 200ms;
  }
}

