@import '../contexts/WalletContext/~@solana/wallet-adapter-react-ui/styles.css';
:root {
  --bg-color: #000;
  --primary-color: #14F195;
}
@font-face {
  font-family: 'soehne';
  src: url("/font/soehne-buch.woff2") format('woff2');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: 'soehne';
  src: url("/font/soehne-kraftig.woff2") format('woff2');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: 'soehne';
  src: url("/font/soehne-halbfett.woff2") format('woff2');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}
* {
  font-family: 'soehne';
  box-sizing: border-box;
}
::selection {
  color: white;
  background: #14F195;
}
body {
  font-size: 16px;
  line-height: 1.5715;
  color: rgba(255, 255, 255);
  margin: 0;
  width: 100%;
  height: 100%;
}
p {
  margin: 0;
}
a {
  text-decoration: none;
  color: #14F195;
}
@keyframes fade {
  0% {
    background-color: inherit;
  }
  100% {
    background-color: #2B2B2B;
  }
}
button {
  height: 56px;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  background-color: transparent;
  color: #ddd;
  border: 1px solid rgba(255, 255, 255, 0.5);
  cursor: 'pointer';
}
button:hover {
  background-color: #2B2B2B;
  animation: fade 100ms;
}
button:disabled,
button[disabled] {
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: #666666;
  cursor: not-allowed;
  pointer-events: all;
}
@keyframes connector-fade {
  0% {
    border-color: #ddd;
    background-color: #ddd;
  }
  100% {
    border-color: #fff;
    background-color: #fff;
  }
}
.connector {
  color: black;
  border-color: #ddd;
  background-color: #ddd;
  box-shadow: 0 0 24px rgba(26, 26, 26, 0.12);
  border-radius: 8px;
  padding: 12px 20px;
  font-size: 18px;
  height: fit-content;
}
.connector:hover,
.connector:focus {
  border-color: #fff;
  background-color: #fff;
  animation: connector-fade 200ms;
}
.hideSvgSoThatItSupportsFirefox {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.mortar img {
  display: block;
}
.mortar .react-loading-skeleton {
  z-index: 0;
}
.mortar .vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.mortar textarea,
.mortar input {
  font-size: 13.33px;
  padding-left: 10px;
  height: 48px;
  color: rgba(255, 255, 255);
  background-color: #2B2B2B;
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(97, 97, 97, 0.5);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  margin-bottom: 2px;
}
.mortar textarea:hover,
.mortar input:hover {
  border-color: #9945FF;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-width: 1px;
}
.mortar textarea:focus,
.mortar input:focus {
  border-color: #14F195;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-width: 2px;
  outline: none;
  box-shadow: none;
}
.mortar textarea:disabled,
.mortar input:disabled {
  border-color: rgba(255, 255, 255, 0.2);
}
.mortar textarea.input-error,
.mortar input.input-error {
  border-color: red;
  border-bottom-width: 2px;
  margin-bottom: 0px;
}
.mortar .text-subtitle,
.mortar .statistic-title {
  margin-bottom: 4px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
}
.mortar .statistic-content {
  color: rgba(255, 255, 255, 0.85);
  font-size: 18px;
}
.mortar .tag {
  padding: 2px 7px;
  line-height: 20px;
  font-size: 12px;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}
.mortar .tag.green {
  border: none;
  background-color: #68f740;
  color: black;
}
.mortar .tag.pink {
  border: none;
  background-color: #e839f6;
}
.mortar .row {
  padding: 24px;
  display: flex;
  flex-direction: row;
}
@media (max-width: 768px) {
  .mortar .row {
    padding: 0;
  }
}
.mortar .row .col {
  flex: 1 1 0;
}
.mortar .row .col-right-pad {
  padding-right: 24px;
}
.mortar .row .col-margin-lr {
  margin-left: 15%;
  margin-right: 15%;
}
.mortar .row.row-image-pad {
  padding: 0px;
}
.mortar .row.row-image-pad .col {
  padding: 6px;
}
.mortar .row.row-image-pad .col:first-child {
  padding-left: 0px;
}
.mortar .row.row-image-pad .col:last-child {
  padding-right: 0px;
}
@media (max-width: 768px) {
  .mortar .row.row-image-pad .col {
    padding: 1px;
  }
}
.mortar table {
  border-collapse: collapse;
}
.mortar table tr > th {
  font-weight: inherit;
  text-align: left;
}
.mortar table tr > th:not(:last-child)::before {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 1.6em;
  background-color: rgba(255, 255, 255, 0.08);
  transform: translateY(-50%);
}
.mortar table tr:hover > td {
  background-color: #2B2B2B;
}
.mortar table tr > th,
.mortar table tr > td {
  padding: 16px;
  position: relative;
}
.mortar .mortar-cover-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.mortar .mortar-cover {
  position: relative;
  z-index: 1;
}
.mortar .mortar-lock {
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  filter: brightness(0) saturate(100%) invert(98%) sepia(100%) saturate(12%) hue-rotate(36deg) brightness(106%) contrast(100%);
}
.mortar button.action {
  height: 40px;
  width: 100%;
  padding: 6px 12px;
  border-radius: 8px;
  background-color: #14F195;
  font-weight: normal;
  border: none;
}
.mortar button.action:hover,
.mortar button.action:focus {
  background-color: rgba(20, 241, 149, 0.75);
  animation: connector-fade 200ms;
}
.mortar button.action:disabled {
  background-color: rgba(255, 255, 255, 0.1);
  animation: none;
}
.mortar button.action.secondary {
  background-color: inherit;
}
.mortar button.action.secondary:hover,
.mortar button.action.secondary:focus {
  background-color: #2B2B2B;
  animation: fade 200ms;
}
.mortar-menu-actions button {
  border-radius: 12px;
  padding: 10px;
  margin: 4px;
}
.mortar-menu-actions button.current-page > span {
  border-bottom: 2px solid #aaa;
  padding-bottom: 4px;
}
.mortar-card {
  margin: 8px;
  border: 2px solid #34373a;
  border-radius: 8px;
}
.mortar-card .mortar-card-head {
  padding: 0 24px;
  min-height: 48px;
  line-height: 3.5;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid #303030;
  display: inline-flex;
  align-items: center;
  width: 100%;
}
.mortar-card .mortar-card-body {
  padding: 24px;
}
.mobile-menu-modal {
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 25px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.mobile-menu-modal ul {
  list-style-type: none;
  padding: 0;
}
.mobile-menu-modal .actions {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}
.mobile-menu-modal .actions .actions-buttons {
  display: flex;
  width: 100%;
}
.mobile-menu-modal .actions .actions-buttons button {
  flex: auto;
}
.mobile-menu-modal .actions .actions-user {
  margin-top: 10px;
}
.mobile-menu-modal .actions .actions-user a {
  display: flex;
  flex: 1;
  padding: 5px;
}
.mobile-menu-modal ul li button {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: left;
}
.mobile-menu-modal .current-user-mobile-badge {
  width: 100%;
}
.mobile-menu-modal .current-user-mobile-badge .mobile-badge {
  display: flex;
}
.mobile-menu-modal .current-user-mobile-badge .balance-container {
  width: 100%;
  margin-top: 10px;
  border-radius: 12px;
  margin-bottom: 10px;
  height: 50px;
  display: flex;
  font-weight: 700;
  flex-direction: column;
}
.mobile-menu-modal .current-user-mobile-badge .balance-container .balance-title {
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
}
.image2 {
  position: absolute;
  width: 400px;
  left: 620px;
  top: 50px;
}
.image1 {
  position: absolute;
  width: 400px;
  left: 620px;
  top: 50px;
}
.App-Bar {
  padding: 0px;
  justify-content: space-between !important;
}
header.App-Bar {
  width: 100%;
  background: transparent;
  display: flex;
  justify-content: center;
  height: fit-content;
  z-index: 10;
}
@media (max-width: 520px) {
  header.App-Bar {
    max-height: 64px;
    position: sticky;
  }
}
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  padding: 10px 14px 10px 6px;
  margin-bottom: 0px;
}
.divider {
  border-left: 1px solid #282828;
  width: 2px;
  height: 40px;
  margin-right: 14px;
}
.wallet-wrapper {
  padding-right: 0.7rem;
}
.app-btn {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  border-width: 0px;
}
@media (min-width: 769px) {
  .app-btn span {
    font-size: 14px;
    font-weight: 600;
  }
  .app-btn div {
    padding: 0px 10px;
  }
}
.app-bar-inner {
  display: flex;
  min-width: 50%;
  justify-content: center;
  position: relative;
  height: auto;
}
.app-bar-inner .app-bar-item {
  cursor: pointer;
  padding: 0 30px;
  color: #547595;
  font-size: 18px;
}
.app-bar-inner .app-bar-item a {
  color: inherit;
}
.app-bar-inner .app-bar-item.active {
  color: white;
}
.app-logo {
  font-size: 40px;
  color: #ddd;
  font-family: 'soehne';
}
#mobile-navbar {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 769px) {
  #mobile-navbar {
    display: none;
  }
}
@media (max-width: 768px) {
  #mobile-navbar {
    padding-top: 4px;
  }
}
#mobile-navbar > a {
  width: 50%;
  max-width: 200px;
}
#mobile-navbar > a img {
  width: 100%;
}
.mobile-menu {
  display: flex;
  align-items: center;
}
.wallet-wrapper {
  padding-left: 0.7rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.wallet-key {
  display: flex;
  align-items: center;
  border: none;
  border-radius: 10px;
  height: 48px;
  padding: 0px 8px;
}
.wallet-key:focus {
  color: unset;
}
h5 {
  font-weight: normal;
}
.app-left {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-left: 15px;
}
.app-right {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-right: 15px;
}
#desktop-navbar {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
@media (max-width: 768px) {
  #desktop-navbar {
    display: none;
  }
}
#width-layout {
  max-width: 1440px;
  margin: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.layout-content {
  flex: auto;
  width: 100%;
  margin: auto;
  position: relative;
}
@media (max-width: 768px) {
  .layout-content {
    padding: 0 !important;
  }
}
body {
  background-color: #000;
}
#main-layout {
  min-height: 100vh;
  background-color: #000;
}
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.48);
  z-index: 11;
  display: none;
}
.loader-container.active {
  display: block;
}
.loader-container .loader-block {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  flex-flow: column;
  align-items: center;
  font-size: 1.7em;
  letter-spacing: 0.16em;
}
.loader-container .loader-title {
  color: #fff;
  text-transform: uppercase;
}
.spinner {
  margin-top: 0.5em;
}
.spinner .line {
  width: 2px;
  height: 24px;
  background: #fff;
  margin: 0 6px;
  display: inline-block;
  animation: spinner-line 1000ms infinite ease-in-out;
}
.spinner .line-1 {
  animation-delay: 800ms;
}
.spinner .line-2 {
  animation-delay: 600ms;
}
.spinner .line-3 {
  animation-delay: 400ms;
}
.spinner .line-4 {
  animation-delay: 200ms;
}
.spinner .line-6 {
  animation-delay: 200ms;
}
.spinner .line-7 {
  animation-delay: 400ms;
}
.spinner .line-8 {
  animation-delay: 600ms;
}
.spinner .line-9 {
  animation-delay: 800ms;
}
@keyframes spinner-line {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes wallet-adapater-fade {
  0% {
    background-color: inherit;
  }
  100% {
    background-color: #1A1F2E;
  }
}
.wallet-adapter-modal-button-close {
  height: inherit;
  background-color: #1A1F2E;
}
.wallet-adapter-modal-button-close:hover {
  background-color: #2A2F3E;
  animation: wallet-adapater-fade 100ms;
}
@keyframes wallet-adapater-fade {
  0% {
    fill: inherit;
  }
  100% {
    fill: #FFF;
  }
}
.wallet-adapter-modal-button-close:hover svg {
  fill: #fff;
  animation: close-svg-fade 100ms;
}
.wallet-adapter-button:not([disabled]):hover {
  background-color: #1A1F2E;
  animation: wallet-adapater-fade 100ms;
}
.wallet-adapter-dropdown-list-item:not([disabled]):hover {
  background-color: #1A1F2E;
  animation: wallet-adapater-fade 100ms;
}
.wallet-adapter-modal-list-more {
  height: inherit;
  display: grid;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.wallet-adapter-modal-list-more span {
  justify-self: flex-end;
}
.wallet-adapter-modal-list-more:hover {
  background-color: #1A1F2E;
  animation: wallet-adapater-fade 100ms;
}
.wallet-adapter-modal-middle-button {
  color: black;
  background-color: #14F195;
}
.wallet-adapter-modal-middle-button:hover,
.wallet-adapter-modal-middle-button:focus {
  border-color: rgba(20, 241, 149, 0.75);
  background-color: rgba(20, 241, 149, 0.75);
  animation: connector-fade 200ms;
}
