
* {
  font-family: 'soehne';
  box-sizing: border-box;
}

::selection {
  color: white;
  background: @primary-color;
}

body {
  font-size: 16px;
  line-height: 1.5715;
  color: rgba(255, 255, 255);
  margin: 0;
  width: 100%;
  height: 100%;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
  color: @primary-color;
}

@keyframes fade {
  0% {
    background-color: inherit;
  }
  100% {
    background-color: #2B2B2B;
  }
}

button {
  height: 56px;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  background-color: transparent;
  color: @fg-color;

  border: 1px solid rgba(255, 255, 255, 0.5);

  &:hover {
    background-color: #2B2B2B;
    animation: fade 100ms;
  }

  cursor: 'pointer',
}

button:disabled,
button[disabled] {
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: #666666;
  cursor: not-allowed;
  pointer-events: all;
}

@keyframes connector-fade {
  0% {
    border-color: #ddd;
    background-color: #ddd;
  }
  100% {
    border-color: #fff;
    background-color: #fff;
  }
}

.connector {
  color: black;
  border-color: #ddd;
  background-color: #ddd;

  box-shadow: 0 0 24px rgba(26, 26, 26, 0.12);
  border-radius: 8px;
  padding: 12px 20px;
  font-size: 18px;
  height: fit-content;

  &:hover, &:focus {
    border-color: #fff;
    background-color: #fff;
    animation: connector-fade 200ms;
  }
}

.hideSvgSoThatItSupportsFirefox {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.mortar {
  // remove line-height... https://stackoverflow.com/questions/11126685/why-does-container-div-insist-on-being-slightly-larger-than-img-or-svg-content
  img { display: block; }

  .react-loading-skeleton {
    z-index: 0;
  }

  .vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  textarea, input {
    // body color gets overriden somewhere...
    font-size: 13.33px;
    padding-left: 10px;
    height: 48px;
    color: rgba(255, 255, 255);
    background-color: #2B2B2B;
    width: 100%;

    border-radius: 8px;

    border: 1px solid rgba(97, 97, 97, 0.5);
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    margin-bottom: 2px;

    &:hover {
      border-color: @secondary-color;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;

      border-bottom-width: 1px;
      // margin-bottom: 1px;
    }

    &:focus {
      border-color: @primary-color;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;

      border-bottom-width: 2px;
      // margin-bottom: 0px;

      outline: none;
      box-shadow: none;
    }

    &:disabled {
      border-color: rgba(255, 255, 255, 0.2);
    }
  }

  textarea.input-error, input.input-error {
    border-color: red;
    border-bottom-width: 2px;
    margin-bottom: 0px;
  }

  .statistic {
  }

  .text-subtitle, .statistic-title {
    margin-bottom: 4px;
    color: rgba(255, 255, 255, 0.60);
    font-size: 14px;
  }

  .statistic-content {
    color: rgba(255, 255, 255, 0.85);
    font-size: 18px;
  }

  .tag {
    padding: 2px 7px;
    line-height: 20px;
    font-size: 12px;
    border-radius: 2px;

    border-radius: 2px;
    background-color: rgba(255, 255, 255, .1);
    color: white;
  }

  .tag.green {
    border: none;
    background-color: rgb(104, 247, 64);
    color: black;
  }

  .tag.pink {
    border: none;
    background-color: rgb(232, 57, 246);
  }

  .row {
    padding: 24px;
    display: flex;
    flex-direction: row;

    @media (max-width: 768px) {
      padding: 0;
    }

    .col {
      flex: 1 1 0;
    }

    .col-right-pad {
      padding-right: 24px;
    }

    .col-margin-lr {
      margin-left: 15%;
      margin-right: 15%;
    }
  }

  .row.row-image-pad {
    padding: 0px;

    .col {
      padding: 6px;
    }

    .col:first-child {
      padding-left: 0px;
    }
    .col:last-child {
      padding-right: 0px;
    }

    @media (max-width: 768px) {
      .col {
        padding: 1px;
      }
    }
  }

  table {
    border-collapse: collapse;

    tr > th {
      font-weight: inherit;
      text-align: left;
    }

    tr > th:not(:last-child)::before {
      position: absolute;
      top: 50%;
      right: 0;
      width: 1px;
      height: 1.6em;
      background-color: rgba(255, 255, 255, 0.08);
      transform: translateY(-50%);
    }

    tr:hover > td {
      background-color: #2B2B2B;
    }

    tr > th, tr > td {
      padding: 16px;
      position: relative;
    }

    // tr {
    //   border-bottom: 1px solid #303030;
    // }
  }

  .mortar-cover-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
  }

  .mortar-cover {
    position: relative;
    z-index: 1;
  }

  .mortar-lock {
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    filter: brightness(0) saturate(100%) invert(98%) sepia(100%) saturate(12%) hue-rotate(36deg) brightness(106%) contrast(100%);
  }


  button.action {
    height: 40px;
    width: 100%;
    padding: 6px 12px;
    border-radius: 8px;
    background-color: @primary-color;
    font-weight: normal;
    border: none;

    &:hover, &:focus {
      background-color: rgba(@primary-color, 0.75);
      animation: connector-fade 200ms;
    }

    &:disabled {
      background-color: rgba(255, 255, 255, 0.1);
      animation: none;
    }
  }

  button.action.secondary {
    background-color: inherit;
    &:hover, &:focus {
      background-color: #2B2B2B;
      animation: fade 200ms;
    }
  }
}

.mortar-menu-actions {
  button {
    border-radius: 12px;
    padding: 10px;
    margin: 4px;
  }

  button.current-page > span {
    border-bottom: 2px solid #aaa;
    padding-bottom: 4px;
  }
}

.mortar-card {
  margin: 8px;
  border: 2px solid #34373a;
  border-radius: 8px;

  .mortar-card-head {
    padding: 0 24px;
    min-height: 48px;
    line-height: 3.5;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid #303030;
    display: inline-flex;
    align-items: center;
    width: 100%;
  }

  .mortar-card-body {
    padding: 24px;
  }
}

.mobile-menu-modal {
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 25px;
  width: 100%;
  height: 100%;

  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    // border-bottom: 1px solid white;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .actions {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    .actions-buttons {
      display: flex;
      width: 100%;
      button {
        flex: auto;
      }
    }
    .actions-user {
      margin-top: 10px;

      a {
        display: flex;
        flex: 1;
        padding: 5px;
      }
    }
  }
  ul li button {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: left;
  }
  .current-user-mobile-badge{
    width: 100%;
    .mobile-badge{
      display: flex;
    }
    .balance-container{
      width: 100%;
      margin-top: 10px;
      border-radius: 12px;
      margin-bottom: 10px;
      height: 50px;
      display: flex;
      font-weight: 700;
      flex-direction: column;
      .balance-title{
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
}

.image2 {
  position: absolute;
  width: 400px;
  left: 620px;
  top: 50px;

  // transform: rotate(18.98deg);
}

.image1 {
  position: absolute;
  width: 400px;
  left: 620px;
  top: 50px;

  // transform: rotate(18.98deg);
}
