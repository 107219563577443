
.app-left {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-left: 15px;
}

.app-right {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-right: 15px;
}

#desktop-navbar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 768px) {
    display: none;
  }
}

#width-layout {
  max-width: 1440px;
  margin: auto;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.layout-content {
  flex: auto;
  width: 100%;
  margin: auto;
  position: relative;

  @media (max-width: 768px) {
    padding: 0 !important;
  }
}

body {
  background-color: @bg-color;
}

#main-layout {
  min-height: 100vh;
  background-color: @bg-color;
}
