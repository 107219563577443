@assetPrefix: '';

@font-face {
  font-family: 'soehne';
  src: url("@{assetPrefix}/font/soehne-buch.woff2") format('woff2');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'soehne';
  src: url("@{assetPrefix}/font/soehne-kraftig.woff2") format('woff2');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'soehne';
  src: url("@{assetPrefix}/font/soehne-halbfett.woff2") format('woff2');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

